.post-writer-input,
.post-writer-btn {
    @apply bg-transparent text-white border border-neutral-800 rounded-lg transition-all duration-300;
}

#post-writer-modal-trade-details .post-writer-input {
    @apply w-[120px] min-h-[35px];
}

.post-writer-input-error,
button.post-writer-btn-error {
    @apply border-error;
}
.post-writer-input-error-text {
    @apply text-error min-h-[20px] text-[.8rem];
}

#post-writer-modal-trade-details-timeframe .date-time-input {
    @apply w-[180px] py-1 px-2 text-base;
}

#post-writer-modal-research>div:first-child {
    @apply min-h-[200px] rounded-t-[1.2rem] border border-neutral-700 border-b-0 text-[1.1rem];
}

#post-writer-modal-research-toolbar {
    @apply border border-neutral-700 bg-neutral-900 rounded-b-[1.2rem];
}


.idui-popover.chart-image-input-question-popover {
    @apply flex flex-wrap !w-[300px] !z-[1100];
}

.idui-popover.chart-image-input-question-popover>div {
    @apply w-full text-center;
    min-width: unset !important;
}
