#audit-scan-alerts::-webkit-scrollbar {
    border-radius: 2.3rem;
    width: 5px;
}

#audit-scan-alerts::-webkit-scrollbar-thumb {
    background: var(--color-fourth);
    width: 5px;
    border-radius: 2.3rem;
}

#audit-scan-alerts::-webkit-scrollbar-track {
    background: var(--color-third);
    border-radius: 2.3rem;
}