@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Cinzel Deco Reg";
  src: url("media/fonts/CinzelDecorative-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Perpetua";
  src: url("media/fonts/Perpetua.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  src: url("media/fonts/WorkSans.ttf") format("truetype");
}

@font-face {
  font-family: "Satoshi";
  src: url("media/fonts/Satoshi.ttf") format("truetype");
}

::-moz-selection {
  color: black;
  background: white;
}

::selection {
  color: black;
  background: white;
}