#big-board {
    position: relative;
    width: 100%;
    color: white;
    /* height: 100vh;
    max-height: 100vh; */
    /* padding: 60px 0; */
    transform: scale(1);
    font-family: "Satoshi";

    --primary-linear-gradient: linear-gradient(to right, #E8D0A6 0%, #FFF1DB 55%, #E8D0A6 100%);
}

.modal.show {
    background: rgba(20, 20, 20, 0.6);
}



.currency-icon img {
    width: auto;
    height: 15px;
    margin-bottom: 3px;
}


.react-datepicker-popper {
    z-index: 1000 !important;
}

.react-datepicker-popper .react-datepicker .react-datepicker__header,
.react-datepicker-popper .react-datepicker .react-datepicker__month,
.react-datepicker-popper .react-datepicker .react-datepicker__month-container,
.react-datepicker__input-time-container,
.react-datepicker__time-container,
.react-datepicker__time  {
    background: var(--color-first) !important;
    color: white !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: inherit !important;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    color: black !important;
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover {
    background: var(--color-third) !important;
    border-radius: 0.3rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    color: black !important;
    background-color: white !important;
}

.react-datepicker-time__input {
    color: black!important;
    padding-left: 0.2rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: var(--color-third) !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #888;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    background-color: transparent !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: white!important;
    color: black!important;
    cursor: default;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
    border-color: white !important;
}

.react-datepicker__input-time-container {
    margin: 0 !important;
    padding: 5px 0 10px 15px;
}
