.bg-gradient-app {
  @apply bg-slate-900 text-white bg-gradient-to-br via-white from-indigo-800 to-yellow-700;
}

.bg-gradient-button {
  @apply bg-slate-900 text-white bg-gradient-to-br from-blue-800 to-indigo-600 via-indigo-500;
}

.bg-gradient-primary {
  @apply bg-gradient-to-br from-neutral-800 to-neutral-700 bg-slate-900 text-white;
}

.text-gradient {
  @apply bg-clip-text text-transparent;
}

.text-gradient-primary {
  @apply bg-gradient-to-br from-emerald-100 to-emerald-200 text-gradient;
}

.text-gradient-secondary {
  @apply bg-gradient-to-br from-indigo-100 to-purple-200 text-gradient;
}

.text-gradient-aqua {
  @apply bg-gradient-to-br from-stone-100 to-blue-200 text-gradient;
}
