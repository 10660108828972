.alpha-post-preview:not(.open):hover {
    @apply bg-neutral-800;
}

.alpha-post-preview:not(.open) .information-container:hover {
    cursor: pointer;
}

.alpha-post-preview::-webkit-scrollbar {
    border-radius: 2.3rem;
    width: 5px;
}

.alpha-post-preview::-webkit-scrollbar-thumb {
    background: var(--color-fourth);
    width: 5px;
    border-radius: 2.3rem;
}

.alpha-post-preview::-webkit-scrollbar-track {
    background: var(--color-third);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 2.3rem;
}


.alpha-post-preview-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.alpha-post-preview .interactions-container {
    border-right: 1px solid var(--color-third);
}


.post-trade-details .token-info .price-at-creation {
    color: grey;
}

.post-trade-details .price-range-info.uptrend {
    color: var(--selection-color);
}

.post-trade-details .price-range-info.downtrend {
    color: var(--error-color);
}

.alpha-post-bottom-fade {
    position: absolute;
    width: 100%;
    height: 200px;
    background: linear-gradient(0deg, var(--color-second) 0%, rgba(255,255,255,0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}