:root {
  --color-first: #151517;
  --color-second: #050505;
  --color-third: #333333;
  --color-fourth: #4e4e4e;

  --light-first: rgb(221, 221, 221);
  
  --primary-blue: #cbfbff;

  --color-primary: #e8d0a6;
  --color-secondary: #aaaaaa;

  --gold-color: #cdbeac;

  --selection-color: #14f195;
  --dark-selection-color: #0e8f59;
  --error-color: #ff3b45;
  --dark-error-color: #8b1919;
}

html {
  background-color: var(--color-first);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
h2 {
  font-size: calc(1.325rem + .9vw);
}
h3 {
  font-size: calc(1.3rem + .6vw);
}
h4 {
  font-size: calc(1.275rem + .3vw);
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
}

a {
  color: var(--primary-blue);
}

ul, ol {
  display: block;
  list-style-type: disc;
  /* margin-block-start: 1em; */
  /* margin-block-end: 1em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
ol {
  list-style-type: decimal;
}

.btn-simple {
  border: none;
  outline: none;
}

.btn-default {
  position: relative;
  background: var(--color-second);
  color: white;
  border: 1px solid var(--color-third);
  border-radius: 0.375rem;
  outline: none;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  overflow: hidden;
  transition: all .3s;
}

.btn-default:active,
.btn-default:enabled:hover {
    /* color: black !important; */
    background: var(--color-third);
}
/* .btn-default:enabled:hover {
  opacity: 0.7;
} */
.btn-default.selected {
  color: black !important;
  background: var(--primary-linear-gradient) !important;
}

.btn-default:disabled {
    opacity: 0.5;
}

.btn-default:enabled::after {
    position: absolute;
    content: "";
    inset: 0;
    background-image: var(--primary-linear-gradient);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s linear;
}
.btn-default:enabled:hover::after {
    color: black;
    opacity: 1;
}



.btn-default-reversed {
  background: white!important;
  color: black!important;
  border: none;
  transition: all .3s;
}

.btn-default-reversed:enabled:hover {
  opacity: .7;
}

.btn-simple-fill {
  border: 1px solid white;
  color: white;
  background: transparent;
  transition: all .3s;
}

.btn-simple-fill:enabled:hover {
  color: black;
  background: white;
  transition: all .3s;
}

.btn-selection,
.btn-error {
  background-image: linear-gradient( 109.6deg,  rgba(5,85,84,1) 11.2%, rgba(64,224,208,1) 91.1% );
  background-size: 200% auto;
  color: white;
  border: 1px solid #222;
  border-radius: .8rem;

  transition: all .5s;
}
.btn-error {
  background-image: radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgba(226,24,24,1) 0%, rgba(160,6,6,1) 90% );
}
.btn-selection:hover,
.btn-error:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}



.input-simple {
  border: none;
  box-shadow: none;
}

.input-simple:focus {
  outline: none;
}

.input-simple::selection {
  background: black;
  color: white;
}


.title {
  font-size: 4rem;
}

.text-luxury {
  font-family: "Cinzel Deco Reg";
}

.text-header {
  font-family: "Work Sans";
}

.text-normal {
  font-family: "Satoshi";
}

.text-normal-base-size {
  font-family: "Satoshi";
  font-size: 1.2rem;
}

.text-gold-highlight {
  color: var(--gold-color);
  filter: drop-shadow(0px 0px 5px var(--gold-color));
}

.header-gradient {
  background: #FFFFFF;
  /* background: linear-gradient(to right, #ABABAB 0%, #FFFFFF 35%, #ABABAB 100%); */
  background: linear-gradient(to right, #c9d8e5 0%, #ddd 35%, #c9d8e5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-deep {
  text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9), 2px 2px 2px rgba(206, 89, 55, 0);
}

.shadow-selection {
  filter: drop-shadow(0px 0px 5px var(--selection-color));
}

.error-selection {
  filter: drop-shadow(0px 0px 5px var(--error-color));
}


.hover-selection-color,
.hover-error-color {
  transition: all .3s;
}

.error-border-color {
  border-color: var(--dark-error-color) !important;
}

.border-main {
  border: 1px solid var(--color-third);
  border-radius: .6rem;
}


.filler-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.photobanner {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0;
  width: 100%;
  height: 100%;
}


.unibot-background {
  position: relative;
  overflow: hidden;
  background: #570970;
  /* background-image: linear-gradient(125deg, #570970 0%, #8B01FE 100%); */
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, #6119ab 0%, #bf97bf 90%);
  z-index: 1;
  /* transition: all .3s; */
}
/* .unibot-background:hover {
  background: #8B01FE;
} */
.unibot-background::before {
  position: absolute;
  content: "";
  inset: 0;
  background-image: radial-gradient(circle 300px at 8.2% 13.8%, #6119ab 0%, #bf97bf 90%);
  z-index: -1;
  opacity: 0;
  transition: opacity .3s;

  /* background-image: linear-gradient(315deg, #570970 0%, #8B01FE 100%); */
}
.unibot-background:hover::before {
  opacity: 1;
}

.loading-square {
  background: var(--color-third);
  width: 60px;
  height: 30px;
  margin: 0 auto;
  opacity: .85;
  border-radius: .2rem;
  animation: loading 2s infinite alternate;
}

@keyframes loading {
  from {
    opacity: .85;
  }
  to {
    opacity: .3;
  }
}


.token-icon,
.pfp-icon {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.pfp-icon {
  min-width: 25px;
  width: 25px;
  height: 25px;
}

.grid.place-items-center.fixed.w-screen.h-screen {
  overflow-y: scroll;
  overflow-x: hidden;
}