#alpha-post-comment-writer-research>div:first-child {
    @apply h-[200px] rounded-t-[1.2rem] border border-neutral-800 border-b-0 text-[1.1rem];
}

.idui-popover.chart-image-input-question-popover {
    @apply flex flex-wrap !w-[300px] !z-[1100];
}

.idui-popover.chart-image-input-question-popover>div {
    @apply w-full text-center;
    min-width: unset !important;
}