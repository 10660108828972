#alpha-post-chat .scroll-container::-webkit-scrollbar {
    border-radius: 2.3rem;
    width: 5px;
}

#alpha-post-chat .scroll-container::-webkit-scrollbar-thumb {
    background: var(--color-fourth);
    width: 5px;
    border-radius: 2.3rem;
}

#alpha-post-chat .scroll-container::-webkit-scrollbar-track {
    background: var(--color-third);
    /* margin-top: 25px;
    margin-bottom: 25px; */
    border-radius: 2.3rem;
}