.btn {
  @apply cursor-pointer font-semibold rounded-md shadow-md py-3 inline-block px-4 text-center focus:outline-none focus:ring-2 disabled:opacity-60 focus:ring-indigo-400 focus:ring-opacity-75 duration-200;
}

.btn-xs {
  @apply text-xs;
}
.btn-sm {
  @apply text-sm;
}
.btn-lg {
  @apply text-lg px-5 py-4;
}
.btn-pill {
  @apply rounded-full;
}
.btn-curve {
  @apply rounded-lg;
}
.btn-xl {
  @apply text-xl px-5 py-4;
}

.btn-2xl {
  @apply text-2xl px-6 py-4;
}

.btn-primary {
  @apply bg-neutral-700 hover:bg-neutral-600 text-white focus:ring-gray-400 dark:bg-neutral-600 hover:dark:bg-neutral-700;
}

.btn-white {
  @apply bg-white text-gray-600 hover:bg-gray-50 focus:ring-gray-400;
}

.btn-light {
  @apply bg-neutral-50 hover:bg-neutral-100 focus:ring-neutral-300 text-neutral-700;
}

.btn-dark {
  @apply bg-neutral-600 hover:bg-neutral-700 focus:ring-neutral-400 text-white;
}

.btn-green {
  @apply bg-green-600 hover:bg-green-700 focus:ring-green-400 text-white;
}

.btn-emerald {
  @apply bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-400 text-white;
}

.btn-blue {
  @apply bg-blue-600 hover:bg-blue-700 focus:ring-blue-400 text-white;
}

.btn-red {
  @apply bg-red-600 hover:bg-red-700 focus:ring-red-400 text-white;
}

.tag {
  @apply inline-flex text-xs leading-5 font-semibold rounded-lg;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
}

.tag-base {
  @apply text-base;
}

.tag-primary {
  @apply text-gray-600 bg-gray-300 dark:text-gray-200 dark:bg-gray-700;
}

.tag-white {
  @apply text-gray-700 bg-white;
}
.tag-light {
  @apply text-gray-600 bg-gray-100;
}
.tag-dark {
  @apply text-gray-100 bg-gray-800;
}
.tag-gray {
  @apply text-gray-100 bg-gray-700;
}
.tag-emerald {
  @apply text-emerald-100 bg-emerald-800;
}
