html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  @apply min-h-screen bg-darkLayout font-sans text-slate-900 antialiased dark:bg-darkLayout dark:text-slate-50;
}

.react-images__view {
  display: flex;
  justify-content: center;
}

.cropper__line--left {
  border-left-width: 2px;
}
.cropper__line--top {
  border-top-width: 2px;
}

.link {
  @apply text-blue-700 hover:text-blue-600 dark:text-blue-200 dark:hover:text-blue-300 cursor-pointer;
}

.content section {
  @apply px-10;
}

.content h3 {
  @apply mt-5 mb-2;
}
.content p {
  @apply mb-3 text-base;
}

.content .list-disc li {
  @apply py-2;
}

.flex-center {
  @apply flex justify-center items-center;
}

.menu-item {
  @apply text-sm font-medium text-neutral-500 dark:text-neutral-400 hover:text-neutral-700 dark:hover:text-neutral-200 flex items-center gap-4;
}

.menu-item.active {
  @apply text-neutral-100;
}

.user-dropdown-menu-item {
  @apply relative flex w-full items-center justify-start space-x-2 rounded-md p-2 text-left text-sm transition-all duration-75 hover:bg-gray-100 dark:hover:bg-neutral-800;
}

.bg-card {
  @apply bg-white dark:bg-neutral-700 dark:text-white;
}

.bg-card-with-hover {
  @apply bg-white dark:bg-neutral-700 dark:text-white hover:dark:bg-neutral-800;
}

.card {
  @apply bg-card border-2 dark:border-neutral-700 rounded-lg p-6 shadow-sm;
}

.card-min {
  @apply bg-neutral-50 dark:bg-neutral-600 dark:text-white bg-opacity-50 rounded-lg shadow-sm;
}

.input {
  @apply border-0 px-3 py-3 placeholder-neutral-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150;
}

.lds-dual-ring {
  display: inline-block;
  width: 22px;
  height: 22px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 18px;
  height: 18px;
  margin-top: 4px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
}

[data-theme='light'] .colormode .lds-dual-ring:after {
  border: 6px solid #3e3e3e !important;
  border-color: #3e3e3e transparent #3e3e3e transparent !important;
}

[data-theme='dark'] .colormode .lds-dual-ring:after {
  border: 6px solid #fff !important;
  border-color: #fff transparent #fff transparent !important;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
